import React from 'react';
import logo from '../assets/logo-3.png';
import {Redirect} from 'react-router-dom';
import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight ,RootLeft,RootValue,FooterUl,FooterLi} from './styled';
import {
MdPowerSettingsNew
} from 'react-icons/md';
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
       isloggedOut:false,
       lcoID: localStorage.getItem("userID"),
       lcoPassword: localStorage.getItem("userPassword"),
       domainName:localStorage.getItem("domain"),
       dashboardData:{},
       errorMessage:"",
       spinnerVisible:false,
       lcoBalance:"",
       lcoName:""

        };
        console.log("username",localStorage.getItem("userID"));
        // this.getDashboardDetails();
        //this.getLCO_Details();
    }

    logOutFunc(){
        if(this.state.isloggedOut){
              return <Redirect to="/login"/>
        }else{
          return null;
        }
       }
       logOut(){
         
         localStorage.removeItem("userID");
         const username = localStorage.getItem("userID");
         if(!username){
            this.setState({isloggedOut:true});
         }
       }

    render(){
        return(
            <Rootcontainer>
         {/* <div style={{width:"100%",height:71,background:"#1869aa",display:"flex"}}>
             <div style={{flex:1}}>
                 <label style={{fontSize:21,color:"#fff", fontWeight: "bold",lineHeight: 2,margintop:3,marginBottom:0}}>{this.props.title}</label>
                 </div>
             <div>
                 <img src={logo} style={{width:57,right:57,float:"right",position:"relative"}}></img>
                 <MdPowerSettingsNew onClick={()=>this.logOut()} value="Logout" className="fontSize"/>
                 </div>

         </div> */}

         <div className="header-div">
                    <h3>Dashboard</h3>
                    <img src={logo} className="logo-img" />
                    <MdPowerSettingsNew onClick={()=>this.logOut()} value="Logout" className="fontSize"/>
                </div>

             <RootDiv>
             <RootLeft>
             <RootTitle>
                                     Name :
                                 </RootTitle>
             
                                 <RootValue >
                                     {this.props.lcoName}
                                 </RootValue>
             </RootLeft>
                                <RootRight>
                                 <RootTitle>
                                     Balance :
             </RootTitle>
             
                                 <RootValue>
                                  {'\u20B9'} {this.props.lcoBalance}
             </RootValue>
             
             </RootRight>
                             </RootDiv>
{this.logOutFunc()}
                      
                             </Rootcontainer>
        );
    }
}